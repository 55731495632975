.otp_input {
  letter-spacing: 16px;
}
.otp_input::placeholder {
  letter-spacing: normal;
}
.resend_otpbtn {
  background: transparent !important;
  color: #9e7c0c;
  border: none !important;
}
.resend_otpbtn_false {
  background: transparent !important;
  color: #5c5c5c;
  border: none !important;
}
