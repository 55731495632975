
@media screen and (max-width:767px) {
    .t_small_none
    {
        display: none !important;
    }    
}
.error-message
{
    color:red;
    font-size: 14px;
}