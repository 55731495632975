@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.press-year-select {
  padding: 5px 5px;
}
.press-month-select {
  padding: 5px 5px;
  margin-left: 10px;
}
.press_realese_title {
  color: #265936;
  font-size: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}
.press_realese_sub {
  color: #8f8579;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.active {
}
.btn-pagination {
  border: 0;
  background-color: transparent;
}
.page_arrow {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.pagination {
  display: flex;
  gap: 10px;
  justify-content: end;
}

/* SkeletonLoader.css */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton-row .skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.skeleton-title {
  width: 70%;
  height: 30px;
}

.skeleton-date {
  width: 30%;
  height: 30px;
}
.description a {
  color: black;
  text-decoration: none;
}
.description a:hover {
  color: #265936;
}

/* press details page */
.press-details-title {
  background-color: #faf1d4;
  color: #265936;
  font-size: 16px;
  font-weight: 800;
  padding: 10px 10px;
}
.press-details p{}