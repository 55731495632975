.profile_card {
  background: #fff;
  border-radius: 20px;
  padding: 20px 24px;
}

.profile_tab li .nav-link.active {
  border: 0;
  border-bottom: 3px solid #9e7c0c;
  color: #9e7c0c;
}

.profile_tab li .nav-link {
  border: 0;
  border-bottom: 3px solid transparent;
  color: #265936;
}

body {
  background-color: #f5f6fa;
}

.profile_form label {
  color: #232323;
  font-size: 14px;
}

.profile_form input,
.profile_form select {
  border: 1px solid #dfeaf2;
  box-shadow: unset !important;
  font-size: 14px;
  padding: 13px 14px;
  border-radius: 15px;
}

.profile_form input:focus,
.profile_form select:focus {
  border: 1px solid #9e7c0c;
}

.profile_form input::placeholder {
  font-size: 14px;
}

.form_save-btn,
.form_save-btn:hover {
  background-color: #265936;
  color: #fff;
  padding: 8px 46px;
  font-size: 16px;
}

.form_cancel-btn,
.form_cancel-btn:hover {
  background-color: #4a4a4a82;
  color: #fff;
  padding: 8px 46px;
  font-size: 16px;
}

.edit_profile-img {
  width: 130px;
  height: 130px;
}

.edit_profile-img img {
  object-fit: cover;
  width: 130px;
  height: 130px;
  border-radius: 120px;
  border: 1px solid #ddd;
  display: block;
}

.password_title {
  font-size: 16px;
  color: #333b69;
}

.edit_profile-img .img_edit {
  position: absolute;
  right: 6px;
  bottom: 0px;
  width: 30px;
  height: 30px;
  background: #265936;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  border: 2px solid #ffffff;
  text-align: center;
  padding: 0px;
}

.edit_profile-img .img_edit input {
  opacity: 0;
  width: 20px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  left: 0;
}

.change_password input {
  padding: 24px 14px !important;
}

.input_from-password .error_message {
  bottom: -20px;
}

.profile_tabs button {
  color: #265936;
  background-color: transparent;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 3px solid transparent;
  padding: 4px 14px;
}
.profile_tabs button.active {
  color: #9e7c0c;
  border-bottom: 3px solid #9e7c0c;
}

.custom-input-style-selected {
  border: none !important;
  box-shadow: unset !important;
  font-size: 14px;
  padding: 0px !important;
  border-radius: 15px;
}
.custom-input-style-selected:focus {
  border: none !important;
  outline: 0 !important;
}
.profile_title {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .profile_title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
