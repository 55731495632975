.liked_videos-tab li a {
    font-size: 14px;
    color: #265936;
    font-weight: 600;
    border-radius: 5px;
    border: 0 !important;
}

.liked_videos-tab li .nav-link.active {
    border: 0 !important;
}

body {
    background-color: #F5F6FA;
}

@media only screen and (max-width: 768px) {
    .mob_padding {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .liked_videos-title .page_title {
        margin-bottom: 10px;
    }

    .liked_videos-title {
        flex-direction: column;
    }
}