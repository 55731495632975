.custom-loading-skeleton {
  width: 100%;
}

.line {
  height: 20px; /* Adjust height as needed */
  margin-bottom: 10px; /* Adjust spacing between lines */
  background-color: #f0f0f0; /* Adjust background color */
  border-radius: 4px; /* Adjust border radius */
}

/* styles.css */
.custom-dropdown-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-dropdown-header {
  cursor: pointer;
  /* padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff; */
  border: 1px solid #dfeaf2;
  box-shadow: unset !important;
  font-size: 14px;
  padding: 13px 14px;
  width: 100%;
  border-radius: 15px;
}

.custom-dropdown-header:hover {
  background-color: transparent;
}

.custom-dropdown-arrow {
  float: right;
}

.custom-dropdown-arrow.open {
  transform: rotate(180deg);
}

.custom-dropdown-options {
  position: absolute;
  top: 74%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 4px 0 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
}

.custom-dropdown-options li {
  padding: 8px;
  cursor: pointer;
}

.custom-dropdown-options li:hover {
  background-color: #4a4a4a82;
  color: #fff;
}
